<template>
  <div>
    <CanceledOrders />
  </div>
</template>

<script>
import CanceledOrders from "../components/ReportCenter/CanceledOrders";
export default {
  components: {
    CanceledOrders,
  },
};
</script>

<style></style>
